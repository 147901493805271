import React from 'react'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
import styled from "styled-components"

const ThumbnailContainer = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 20% 80%;
    grid-template-rows:  1fr;
    margin-bottom: 20px;
    @media screen and (max-device-width: 800px) {
        grid-template-columns: 50% 50%;
    }
    img {
        height: auto;
        margin-block-start: 1em;
        margin-block-end: 1em;
        width: 90%;
    }
    p{
        width: 90%
    }
    span {
        display: inline-block;
        margin-bottom: 15px;
    }
`

const CraftersPage =  () => {
    return (
        <>
        <Layout>
            <h1><span className="font-secondary">team</span></h1>
            <ThumbnailContainer>
                <StaticImage src='../assets/images/collaborator/sura-saif-unleaving-creators-indie-video-game-devs-couple.jpg'/>
                <p>
                    <span>At its core are a tiny team; dynamic husband-and-wife duo, <span className="font-secondary">sura karnawi</span> and <span className="font-secondary">saif jabur</span>, based in Toronto, Canada.</span> 
                    <span> They have worked on writing the game, development, art direction, and production. Rooted in their love for art and puzzle platformer games like "Limbo", their passion is dedicated to exploring and expressing the depths of human emotions and vulnerabilities.</span> 
                </p>

            </ThumbnailContainer>

            <ThumbnailContainer>
                <StaticImage src='../assets/images/team/orangutan-matter-team-sura-karnawi-self-portrait.jpg'/>
                <p><span className="font-secondary">sura karnawi</span> (she/her) - <i>Toronto, Canada</i>: a game developer, producer, and artist with over a decade of experience of conceptualization-to-launch in software, including BBC.co.uk, and game development of Unleaving.
                She's the co-creator and lead artist of Unleaving, bringing a unique visual style that combines traditional hand-painted impressionist art, pencil illustrations, and animation to create an emotional visual impact.
                Over three years, <span className="font-secondary">sura</span> produced over 22,500 paintings for the game while raising young children. Her commitment to artistic expression and storytelling is evident in every frame, each a literal painting, of "Unleaving". 
                <span className="font-secondary">sura</span> has performed live painting at PAX East 2023, using it as a platform to gather player feedback on her demo's visuals of Unleaving and to encourage players to view video games as a cultural medium (Unleaving was chosen as  hidden gem at PAX East 2023). 
                Since 2015, <span className="font-secondary">sura</span> has participated in Art Battle Toronto, as a live painter with her paintings featured in the Toronto Star.
                <span className="font-secondary">sura</span> led the art direction and production, managing all aspects of game development and user research, and collaborating with individuals from diverse backgrounds, those challenging traditional roles, leading thinkers in art and gaming such as Diala Brisly and Macy Kuang, and AbleGamers. Her vision is to contribute to the growing awareness of the need for inclusivity in the video game industry. 
                Sura's dedication extends to participating in events and initiatives like BBC Women in STEM and Pixelles, as well as mentoring others.
                </p>
            </ThumbnailContainer>

            <ThumbnailContainer>
                <StaticImage src='../assets/images/team/orangutan-matter-team-saif-jabur.jpg'/>
                <p><span className="font-secondary">saif jabur</span> (he/him) - <i>Toronto, Canada</i>: A passionate game creator and indie developer. Skilled in all aspects of game creation from conception to launch, including mechanics,level design, and art direction. Technically proficient in prototyping game mechanics and knowledgeable about current video game trends. Saif was the co-creator of Unleaving and he wore many hats during development.<br/>
                <span className="font-secondary">saif</span> is also a pharmacist! His leadership & management skills developed through years of lead pharmacist experience. He still contributes part-time to research and service in the medical field.<br />
                <span className="font-secondary"> saif</span>'s fascination with video games began during the so-called 'golden age' of arcade games in the 1990s. At that time, arcade game design was centered around replayability in short play sessions to bring in more coins. So, his fascination came out of a need: “How can I play more games and spend less money?”. The answer was simple; “Make my own arcade machine”. And indeed in 2013 he created one. It took 10 months, and every part was made from high quality material including the buttons which were the original Sanswa buttons from Japan to give the same tactile feedback he remembered as a boy playing in arcades. 
                <span className="font-secondary">saif</span> is deeply inspired by indie game darlings like Limbo, Portal, Thomas Was Alone. That combined with his time spent in Middle East, Europe and North America experiencing different cultures cemented his vision to bring video games towards grandeur and intellectually stimulating experiences. Saif's perspective as a parent has reinforced his commitment to be an independent game dev, as he wants to show himself as a role model for pursuing his dreams.
                </p>
            </ThumbnailContainer>

            <iframe width="100%" height="315" src="https://www.youtube.com/embed/wyGeF4UYw6k?si=ZFJla37gqGv0JkSH" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

            <h2><span className="font-secondary">Collaborators:</span></h2>
            <p>While <span className="font-secondary">sura & Saif</span> are core of the team, they recognize the invaluable contributions of the Unleavin's collaborators who enrich our projects with their expertise and creativity. </p>

            <ThumbnailContainer>
                <StaticImage src='../assets/images/collaborator/diala-brisly-portrait-unleavingcollaborator.jpeg'/>
                <p><span className="font-secondary">diala brisly</span> (she/her) - <i>Valence, France</i>: Unleaving's character artist, she was a mentor to Sura, she is an award-winning artist and author with a focus on social injustice that drive the heart of her work, originally from Syria and a war survivor, particularly her desire to give a voice to the most voiceless, especially children.</p>
            </ThumbnailContainer>

            <ThumbnailContainer>
                <StaticImage src='../assets/images/collaborator/Jayson-jayson-fuerstengberg-photo-unleaving-collaborator.jpg'/>
                <p><span className="font-secondary">jayson fuerstenber</span> (he/him) - <i>Vancouver Island, Canada</i>: is Unleaving's graphite artist, breathing life into beautiful illustrations of environment art, puzzle drawings, and interactable objects. Embracing challenges within rigid ratios and restrictions, he maintains adaptability while staying creative. Inspired by the magical elements of nature, Jayson reflects a deep connection with the subject. In his artistic journey, he not only seeks captivating results but also finds immense meaning and therapeutic value in the creative process.</p>
            </ThumbnailContainer>


            <ThumbnailContainer>
                <StaticImage src='../assets/images/collaborator/francois-messier-unleaving-collaborator.jpg'/>
                <p><span className="font-secondary">francois messier</span> (he/him) - <i>Montreal, Quebec</i>: With 16 years as a level designer, his expertise and patience were crucial in preserving Unleaving's vision. Ensuring each puzzle followed level design principles while serving the metaphor and story in a hand-painted world. His connection with <span className="font-secondary">sura & saif</span>, from LinkedIn to a bench in Montreal, not only grew into a friendship but also profoundly enriched Unleaving's collaborative journey, leaving a lasting impact on the gaming experience's depth.</p>
            </ThumbnailContainer>


            <ThumbnailContainer>
                <StaticImage src='../assets/images/collaborator/asmaa al-issa_portrait-unleaving-collaborator.jpg'/>
                <p><span className="font-secondary">asmaa al-isaa</span> (she/her) - <i>Calgary, Canada</i>:  She's Unleaving artistic process adviser and aesthetics consultant. Asmaa has played a pivotal role in establishing artistic cohesion and rules for the game, ensuring each frame of Unleaving is a work of art while considering gaming elements, bringing Unleaving's vision to life. As an artist and educator with an interdisciplinary practice, Asmaa is dedicated to cultivating creative energy by engaging her lived experiences with the land, materials, and people around her. </p>
            </ThumbnailContainer>


            <ThumbnailContainer>
                <StaticImage src='../assets/images/collaborator/macy-kuang-miaomiao-games-unleaving collaborator.png'/>
                <p><span className="font-secondary">macy kuang</span> (she/her) - <i>Toronto, Canada</i> Macy, a Unity game developer consultant, brings her extensive skills to Unleaving, contributing to engineering and technical decisions alongside <span className="font-secondary">saif & sura</span> . Known for her extraordinary productivity, she is not only a mother but has also created video games to teach her native language to her children. Macy has successfully developed and released numerous video games with various companies, and she runs a channel called "Code To Create" to assist fellow developers. Additionally, her role included valuable contributions to the simplified Chinese translation. </p>
            </ThumbnailContainer>

            <ThumbnailContainer>
                <StaticImage src='../assets/images/collaborator/corina-diaz-unleaving-collaborator-communication-manager.jpg'/>
                <p><span className="font-secondary">corina diaz</span> (she/her), <i>Ontario, Canada</i>: strategy, marketing and community management consultant. Her incredible contribution helps in crafting authentic communication, adapting to Unleaving's unconventional nature while staying true to its vision. Collaborating with Sura, she devises creative ideas for effective communication and strategy, with a shared vision of connecting meaningfully with the community.</p>
            </ThumbnailContainer>

            <ThumbnailContainer>
                <StaticImage src='../assets/images/collaborator/osama-dorias-black-and-white-photo.jpg'/>
                <p>
                    <span><span className="font-secondary">osama dorias</span> (he/him): Mentor - Osama bringing extensive experience from Blizzard Entertainment, a professor Dawson College, advisor @ GDC & TEDx Speaker.</span>
                    <span> His mentorship guided <span className="font-secondary">sura & saif</span>, helping them set clear goals and create a cohesive world in Unleaving's game & level design. Additionally, he endorsed them as Iraqi game developers, acknowledging their talents and journey.</span></p>
            </ThumbnailContainer>

            <ThumbnailContainer>
                <StaticImage src='../assets/images/collaborator/alicia-enstorm-headshot-unleaving-collaborator.jpg'/>
                <p><span className="font-secondary">alicia enstorm</span> (she/her) - Unleaving collaborator and former violinist with Cirque du Soleil, delivers tracks that perfectly capture the game's essence, reflecting her classical training and versatile style showcased in Call of Duty and beyond.</p>
            </ThumbnailContainer>

            <blockquote>“With Unleaving, players can treat their experience as their own personal journey. Like children
            expressing themselves freely, players are encouraged not only to solve puzzles, but to look further into the
            questions that each challenge poses and interpret the experience in their own unique way. We want to
            inspire introspection and curiosity, inviting players to embrace the boundless freedom of expression
            reminiscent of childhood, exploring wider perspectives, inhabited from judgment—a childlike spirit
            transcending status, age, beliefs.” - Sura Karnawi, orangutan matter studio co-lead.</blockquote>


        </Layout>
        </>
    )
}

export default CraftersPage